import React, { type SyntheticEvent, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import range from 'lodash/range';
import Pagination from '@atlaskit/pagination';
import { token } from '@atlaskit/tokens';
import { cssMap } from '@atlaskit/css';
import { Flex } from '@atlaskit/primitives';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

/*
 * The react-redux `connect` function is passing in a `this.props.dispatch` function but this component does not use it.
 * It is not a problem but it must be either defined in this Props type, or the type must be inexact.
 */
type Props = {
	currentPage: number;
	totalPages: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onPageChange: (arg1: SyntheticEvent<any>, arg2: number) => void;
	intl: IntlShape;
};

function Paginator(props: Props) {
	const {
		currentPage,
		totalPages,
		intl: { formatMessage },
	} = props;

	const onPageChange = (
		data: MouseEvent<HTMLElement>,
		event: number,
		// @ts-expect-error - TS2304 - Cannot find name 'UIAnalyticsEvent'.
		analyticsEvent: undefined | UIAnalyticsEvent,
	) => {
		props.onPageChange(data, event);
		if (analyticsEvent) {
			fireUIAnalytics(analyticsEvent, 'pagination');
		}
	};

	const content = (
		<Pagination
			selectedIndex={currentPage - 1}
			// @ts-expect-error - TS2322 - Type '(data: MouseEvent<HTMLElement>, event: number, analyticsEvent: undefined | UIAnalyticsEvent) => void' is not assignable to type '(event: SyntheticEvent<Element, Event>, page: number, analyticsEvent?: UIAnalyticsEvent | undefined) => void'.
			onChange={onPageChange}
			pages={range(1, totalPages + 1)}
			{...(fg('jfp-a11y-team_directory_paginator-accessible-names')
				? {
						previousLabel: formatMessage(messages.previousButton),
						nextLabel: formatMessage(messages.nextButton),
					}
				: {
						previousLabel: formatMessage(messages.previousButtonOld),
						nextLabel: formatMessage(messages.nextButtonOld),
					})}
		/>
	);

	if (totalPages > 1) {
		return isVisualRefreshEnabled() ? (
			<Flex
				alignItems="center"
				justifyContent="center"
				xcss={styles.paginationContainer}
				testId="global-pages.directories.directory-base.content.paginator.container"
			>
				{content}
			</Flex>
		) : (
			<Container data-test-id="global-pages.directories.directory-base.content.paginator.container">
				{content}
			</Container>
		);
	}
	return null;
}

export default injectIntl(Paginator);

const styles = cssMap({
	paginationContainer: {
		paddingBottom: token('space.300'),
		marginLeft: token('space.negative.150'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingBottom: token('space.300'),
	marginLeft: token('space.negative.150'),
});
